import styles from '@/components/sentiment/aura.module.scss'
import classNames from 'classnames/bind'
import 'animate.css'

const Aura = ({ profileImage, neutralFadeIn = false, goodFadeIn = false, badFadeIn = false }) => {
	return (
		<div
			className={'animate__animated animate__fadeIn animate__delay-0.25s animate__slow aura-container z-0 ' + styles.ChatAuraContainer}>
			<div className={styles.ChatAuraProfile} style={{ backgroundImage: `url("${profileImage}")` }} />
			<ul className={styles.ChatAura}>
				<li className={classNames(styles.ChatAuraItem,
					{ [styles.fadedIn]: goodFadeIn === true },
					{ [styles.fadedOut]: goodFadeIn === false }
				)}>
					<div className={styles.GoodItem} />
				</li>
				<li className={classNames(styles.ChatAuraItem,
					{ [styles.fadedIn]: badFadeIn === true },
					{ [styles.fadedOut]: badFadeIn === false }
				)}>
					<div className={styles.BadItem} />
				</li>
				<li className={classNames(styles.ChatAuraItem,
					{ [styles.fadedIn]: neutralFadeIn === true },
					{ [styles.fadedOut]: neutralFadeIn === false }
				)}>
					<div className={styles.NeutralItem} />
				</li>
			</ul>
		</div>
	)
}

export default Aura