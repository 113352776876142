export const placeholderAvatarUrl = (seed = 'Charlie') => {
  return 'https://api.dicebear.com/6.x/notionists/svg?' +
    'seed=' + seed +
    '&lips=variant14' +
    '&eyes=variant05' +
    '&brows=variant09' +
    '&gesture=hand,ok,waveOkLongArms,okLongArm' +
    '&backgroundColor=b6e3f4,c0aede,d1d4f9' +
    '&glassesProbability=0' +
    '&gestureProbability=99' +
    '&asdg'
}