import VibelAppName from '@/components/VibelAppName'
import NavLink from '@/components/NavLink'

const GuestNavigation = ({ user }) => {
	return (
		<div className={'drop-shadow-md sticky top-0 z-50 bg-white'}>
		<div className="py-4 flex justify-between items-center border-b max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
			<div>
				<VibelAppName route={'/'} />
			</div>
			<div className={'flex space-x-8'}>
				{/*<NavLink*/}
				{/*	href="/pricing"*/}
				{/*	active={router.pathname === '/pricing'}>*/}
				{/*	Pricing*/}
				{/*</NavLink>*/}

				<NavLink
					href={process.env.NEXT_PUBLIC_DISCORD_INVITE}
					active={false}>
					Support
				</NavLink>

				{user ?
					<NavLink
						href="/dashboard"
						active={false}>
						Dashboard
					</NavLink>
					:
					<NavLink
						href="/login"
						active={false}>
						Login/Register
					</NavLink>
				}
			</div>
		</div>
		</div>
	)
}

export default GuestNavigation