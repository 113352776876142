import Head from 'next/head'
import {useAuth} from '@/hooks/auth'
import Aura from '@/components/sentiment/aura'
import auraStyles from '@/components/sentiment/aura.module.scss'
import styles from './index.module.scss'

import GuestLayout from '@/components/Layouts/GuestLayout'
import TwitchChatMessage from '@/components/TwitchChatMessage'
import IconDiscord from '@/components/icons/IconDiscord'
import VodExamplePage_IMG from '@/images/vod-review-page-example.png'
import {placeholderAvatarUrl} from '@/lib/placeholder'
import ButtonLink from '@/components/ButtonLink'

export default function Home () {
  const {user} = useAuth({middleware: 'guest'})

  return (
    <GuestLayout user={user}>
      <Head>
        <title>Vibel - Live Stream Chat Engagement and Vibe</title>
      </Head>

      <div className={'flex justify-center items-center flex-col space-y-12'}>
        <div className={'mt-24'}>
          <h1 className={'text-5xl font-bold text-center mb-4'}>Live Stream Chat
            Engagement - Analyzed</h1>
          <p className={'text-xl font-medium text-center'}>Track Chat Engagement
            and Sentiment.<br />Do they like the new setup? Play a New Game?
            More React Content?</p>
        </div>

        <div className={'flex space-x-5'}>
          <ButtonLink
            className={'py-5 px-5 text-white bg-gradient-to-br' +
              ' from-pink-500 to-yellow-500 hover:from-green-400 hover:to-blue-500' +
              ' border-0'}
            url={'/login'}
            name={'Get Started For Free'} />
          <ButtonLink
            className={''}
            url={process.env.NEXT_PUBLIC_DISCORD_INVITE}
            name={'Join the Community'} />
        </div>

        <div className={'h-[40vw] w-[40vw] ' + auraStyles.AuraBounds}>
          <Aura
            profileImage={placeholderAvatarUrl('Molly')}
            neutralFadeIn={true}
            goodFadeIn={true}
            badFadeIn={true} />
        </div>
      </div>

      <div className={'mt-16'}>
        <h3 className={'text-3xl font-bold my-4'}>Real-time Vibe Check</h3>
        <div className={'flex justify-between items-center flex-col lg:flex-row'}>
          <div className="border-2 w-full flex">
            <div className={'px-4 py-2 flex flex-col items-center justify-center min-w-[300px] ' +
              styles.TwitchChatItem}>
              <div className={'relative h-full w-full'}>
                <TwitchChatMessage
                  message={'This is great!'}
                  color={'#FFFF50'}
                  username={'chatter_one'}
                  timestamp={'12:34'}
                  position={1}
                />
                <TwitchChatMessage
                  message={'That is one great take'}
                  color={'#AA64EA'}
                  username={'chatter_two'}
                  timestamp={'12:35'}
                  position={2}
                  padding={'mt-5'}
                />
                <TwitchChatMessage
                  message={'I like your shirt'}
                  color={'#359BFF'}
                  username={'chatter_three'}
                  timestamp={'12:36'}
                  position={3}
                  padding={'mt-10'}
                />
                <TwitchChatMessage
                  message={'Big W'}
                  color={'#FFFF50'}
                  username={'chatter_four'}
                  timestamp={'12:34'}
                  position={4}
                  padding={'mt-[3.75rem]'}
                />
                <TwitchChatMessage
                  message={'Poggers'}
                  color={'#AA64EA'}
                  username={'chatter_five'}
                  timestamp={'12:35'}
                  position={5}
                  padding={'mt-20'}
                />
              </div>
            </div>
            <div className={'flex justify-center items-center w-full'}>
              <div className={'h-96 w-96 my-14'}>
                <Aura
                  profileImage={placeholderAvatarUrl('Snowball')}
                  neutralFadeIn={false}
                  goodFadeIn={true}
                  badFadeIn={false} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={'mt-16'}>
        <h3 className={'text-5xl font-bold my-4 text-right'}>VOD Review</h3>
        <div className={'flex justify-between items-center flex-col lg:flex-row'}>
          <div className="border-2 w-full flex">
            <img src={VodExamplePage_IMG.src} alt="Vod Review Example Page" />
          </div>
        </div>
      </div>


      {/* Footer */}
      <div className="mt-12 pb-4 flex flex-col justify-center items-center">
        <div>
          <a href="https://twitter.com/Benjjjy_" target={'_blank'} rel="noreferrer">
            made
            by <span className={'text-gray-500 hover:text-gray-700 font-bold'}>benjjy_</span>
          </a>
        </div>
        <div>
          <a
            href={process.env.NEXT_PUBLIC_DISCORD_INVITE}
            target="_blank"
            className={'flex flex-col justify-center items-center' +
              ' text-gray-500 fill-gray-500 hover:text-gray-700 hover:fill-gray-700'} rel="noreferrer">
            <span className={'font-bold'}>Support</span><IconDiscord />
          </a>
        </div>
      </div>
    </GuestLayout>
  )
}
