import styles from '@/pages/index.module.scss'

const TwitchChatMessage = ({ message = '', username = 'chatter_42069', timestamp = '12:34', color = '#ffff50', position = 3, padding = ''}) => {
	return (
		<p className={'absolute m-auto inset-0 flex items-center h-full justify-start animate__animated animate__delay-' + position + 's ' + padding + ' ' + styles.slideInUp}>
			<span className={'mr-2 ' + styles.TwitchChatTimestamp}>{timestamp}</span>
			<span className={styles.TwitchChatUsername} style={{color: color}}>{username}</span>
			<span>: {message}</span>
		</p>
	)
}

export default TwitchChatMessage