const VibelAppName = ({ route }) => {
	return (
		<a href={route} className={'font-montserrat flex flex-col'}>
			<div className={'text-2xl font-black flex justify-center leading-7'}>
				<div>
					Vibel
				</div>
			</div>
			<div className={'text-sm text-gray-600 font-black flex justify-end leading-[0] -mr-1'}>
				<div>
					Beta
				</div>
			</div>
		</a>
	)
}

export default VibelAppName