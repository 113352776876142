const ButtonLink = ({ name, url, className, ...props }) => (
	<a href={url}
		className={`${className} cursor-pointer inline-flex items-center px-4 py-2 
		border-transparent rounded-md font-semibold text-xs uppercase tracking-widest ring-gray-300 
		border border-gray-700
		hover:bg-gray-700 hover:text-white 
		active:bg-gray-700  active:text-white
		focus:border-gray-700 	
		focus:ring focus:outline-none disabled:opacity-25 transition ease-in-out duration-150`}
		{...props}
	>
		{name}
	</a>
)

export default ButtonLink
