import Head from 'next/head'
import GuestNavigation from '@/components/GuestNavigation'

const GuestLayout = ({ children, user }) => {
    return (
        <div className={'bg-white relative'}>
            <Head>
                <title>Vibel - Chat's Vibe Analysed</title>
            </Head>

            <GuestNavigation user={user} />

            <div className={'max-w-7xl mx-auto px-4 sm:px-6 lg:px-8'}>

                <div className="font-sans text-gray-900 antialiased">
                    {children}
                </div>
            </div>
        </div>
    )
}

export default GuestLayout
